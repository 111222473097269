import { isBefore, isAfter } from 'date-fns'

import routesConfig from '@mindfulchefuk/config/routesConfig'

import { CMSRecipeDeliveryRedirect } from '@mindfulchefuk/features/CMS/interfaces'
import { useGetDeliveries } from '@mindfulchefuk/features/Delivery/hooks/useGetDeliveries'
import { useAvailableDates } from '@mindfulchefuk/features/Operations/hooks/useAvailableDates'

type TUseCMSRecipeDeliveryURLResponse = {
  url: string | null
  hasDelivery: boolean
  targetDeliveryDate: Date
}

export const useCMSRecipeDeliveryURL = (
  deliveryDates: CMSRecipeDeliveryRedirect,
  isLoggedOut: boolean
): TUseCMSRecipeDeliveryURLResponse => {
  const { data: deliveries } = useGetDeliveries()

  const {
    recipes: { firstAvailableDeliveryDate, availableDates },
  } = useAvailableDates()

  let targetDeliveryDate =
    firstAvailableDeliveryDate > deliveryDates?.firstDeliveryDate
      ? firstAvailableDeliveryDate
      : deliveryDates?.firstDeliveryDate

  if (isLoggedOut) {
    return {
      url: routesConfig.chooseRecipes.pathname,
      hasDelivery: false,
      targetDeliveryDate: new Date(targetDeliveryDate),
    }
  }

  if (!deliveryDates || !availableDates.length) {
    return {
      url: null,
      hasDelivery: false,
      targetDeliveryDate: new Date(targetDeliveryDate),
    }
  }

  if (deliveries?.length) {
    const deliveriesWithinPromoDates = deliveries.filter(
      (delivery) =>
        !isBefore(
          new Date(delivery.deliveryDate),
          new Date(targetDeliveryDate)
        ) &&
        !isAfter(
          new Date(delivery.deliveryDate),
          new Date(deliveryDates.lastDeliveryDate)
        )
    )

    if (deliveriesWithinPromoDates.length) {
      // Deliveries are ordered by delivery date descending
      const firstEditableDeliveryWithinPromoDates =
        deliveriesWithinPromoDates.pop()
      targetDeliveryDate = firstEditableDeliveryWithinPromoDates.deliveryDate
    }
  }

  return {
    url: `${routesConfig.delivery.pathname(targetDeliveryDate)}?page=recipes`,
    targetDeliveryDate: new Date(targetDeliveryDate),
    hasDelivery: deliveries
      ? deliveries.some(({ deliveryDate }) =>
          targetDeliveryDate.includes(deliveryDate)
        )
      : false,
  }
}
